export default [
	{
		id: 1568358,
		technology: 'abs',
		source_set: 'abs_all_day',
		c_el: null,
		c_th: 0.1,
		grant: 0.2,
		length_district: null,
		mass_flow_rate: 6,
		p_1: 2500,
		p_2: null,
		tee: 100,
		temperature: 500,
		co2: 1428.048,
		dscr: 7.90299130948749,
		irr: 0.525879041863447,
		npv: 13352285.4517139,
		pb: 1.90157203207089,
		pes: 632.808,
		p_actual: 2500,
		p_actual_2: null,
		__relativeBestPerformances: ['co2', 'dscr', 'irr', 'npv', 'pb', 'pes'],
	},
	{
		id: 2008992,
		technology: 'orc',
		source_set: 'orc_all_day',
		c_el: 0.1,
		c_th: null,
		grant: 0.2,
		length_district: null,
		mass_flow_rate: 28,
		p_1: 2300,
		p_2: null,
		tee: 100,
		temperature: 500,
		co2: 7673.46653999999,
		dscr: 6.96317554507426,
		irr: 0.463338406008166,
		npv: 26690276.8753255,
		pb: 2.15822610625573,
		pes: 3400.32758999999,
		p_actual: 2300,
		p_actual_2: null,
		__relativeBestPerformances: ['co2', 'dscr', 'irr', 'npv', 'pb', 'pes'],
		__overallBestPerformances: ['co2', 'npv', 'pes'],
	},
	{
		id: 3163467,
		technology: 'he',
		source_set: 'he_all_day',
		c_el: null,
		c_th: 0.1,
		grant: 0.2,
		length_district: null,
		mass_flow_rate: 8,
		p_1: 3020,
		p_2: null,
		tee: 100,
		temperature: 500,
		co2: 2516.823304,
		dscr: 8.48896271202331,
		irr: 0.564871553260064,
		npv: 15352817.0940615,
		pb: 1.7703113741489,
		pes: 1063.811912,
		p_actual: 3020,
		p_actual_2: null,
		__relativeBestPerformances: ['co2', 'dscr', 'irr', 'npv', 'pb', 'pes'],
	},
	{
		id: 3327959,
		technology: 'district',
		source_set: 'district',
		c_el: null,
		c_th: 0.1,
		grant: 0.2,
		length_district: 2500,
		mass_flow_rate: 5,
		p_1: 2100,
		p_2: null,
		tee: 100,
		temperature: 500,
		co2: 2037,
		dscr: 12.7765575008354,
		irr: 0.850177415592935,
		npv: 13382081.7377834,
		pb: 1.17622507023805,
		pes: 861,
		p_actual: 2100,
		p_actual_2: null,
		__relativeBestPerformances: ['co2', 'dscr', 'irr', 'npv', 'pb', 'pes'],
		__overallBestPerformances: ['dscr', 'irr', 'pb'],
	},
	{
		id: 5034238,
		technology: 'match',
		source_set: 'match',
		c_el: 0.1,
		c_th: 0.1,
		grant: 0.2,
		length_district: 2500,
		mass_flow_rate: 19,
		p_1: 500,
		p_2: 2100,
		tee: 100,
		temperature: 500,
		co2: 3705.1449,
		dscr: 5.96066883428405,
		irr: 0.396617079123607,
		npv: 17386635.6669459,
		pb: 2.5212115723295,
		pes: 1600.20165,
		p_actual: 2599.60922674103,
		p_actual_2: 2100,
		__relativeBestPerformances: ['co2', 'dscr', 'irr', 'npv', 'pb', 'pes'],
	},
]
