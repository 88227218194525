/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import React from 'react'
import color from 'color'
import {Form, Field} from 'react-final-form'

import {Layout} from './layout'

const spaceBetween = 40
const leftLabelWidth = 135
const rightLabelWidth = 190
const leftSuffixWidth = 35
const rightSuffixWidth = 60

const leftFieldLayout = {
	suffixWidth: leftSuffixWidth,
	asGrid: true,
	labelWidth: leftLabelWidth,
}
const rightFieldLayout = {
	suffixWidth: rightSuffixWidth,
	asGrid: true,
	labelWidth: rightLabelWidth,
}

const DEBUG = false

const Select = ({children, ...props}) => (
	<div className="select is-fullwidth">
		{/* {JSON.stringify(props)} */}
		<select {...props}>{children}</select>
	</div>
)

const darkOrange = color('#e74623').lighten(0.1)

const buttonCss = css`
	background-color: ${darkOrange.string()} !important;
	&:hover {
		background-color: ${darkOrange.darken(0.2).string()} !important;
	}
	&:focus:not(:active),
	&.is-focused:not(:active) {
		box-shadow: 0 0 0 0.125em ${darkOrange.alpha(0.25).string()} !important;
	}
`

const FormItem = ({
	name,
	type,
	label,
	component,
	suffix,
	asGrid,
	error,
	labelWidth = 162,
	suffixWidth = 0,
}) => {
	const suffixStyle = {
		lineHeight: '35px',
		paddingLeft: 10,
		flexBasis: suffixWidth,
	}
	return (
		<div
			style={{
				display: 'flex',
				marginTop: 10,
			}}
			className="control is-danger"
		>
			<label
				className="label"
				style={{
					marginRight: 15,
					lineHeight: '35px',
					//display: 'inline-block',
					//backgroundColor: 'red',
					...(labelWidth ? {width: labelWidth} : {}),
				}}
			>
				{label}
			</label>
			<div style={{display: 'flex', flex: 1}}>
				<div
					style={{
						flex: 1,
					}}
				>
					{/* {JSON.stringify(props)} */}
					{
						<Field
							type={type}
							name={name}
							render={({input, meta}) => {
								const comp = component({input, meta})
								return React.cloneElement(comp, {
									className: [comp.props.className || '', 'input', error && ' is-danger'].join(' '),
								})
							}}
						/>
					}
					{error && <p className="help is-danger">{error}</p>}
				</div>
				{suffix && <span style={suffixStyle}>{suffix}</span>}
			</div>
		</div>
	)
}

function InputForm({initialValues, onSubmit, loading}) {
	return (
		<Form
			initialValues={initialValues}
			onSubmit={onSubmit}
			render={({values, handleSubmit}) => (
				<form
					noValidate
					onSubmit={e => {
						e.preventDefault()
						!loading && handleSubmit()
					}}
				>
					<Layout className="columns">
						<div
							className="column"
							style={{
								marginRight: spaceBetween,
								...(DEBUG && {
									backgroundColor: color('#3284cd')
										.alpha(0.2)
										.string(),
								}),
							}}
						>
							<h4 className="title is-4">WASTE HEAT SOURCE INFORMATION</h4>

							<FormItem
								name="emission_type"
								label="type of emission"
								asGrid
								labelWidth={leftLabelWidth}
								component={({input}) => (
									<Select {...input}>
										<option value="fumes">Fumes</option>
										<option value="water">Water</option>
									</Select>
								)}
							/>

							<FormItem
								name="emission_profile"
								label="emission profile"
								asGrid
								labelWidth={leftLabelWidth}
								component={({input}) => (
									<Select {...input}>
										<option value="continuous">Continuous</option>
										<option value="discontinuous">Discontinuous</option>
									</Select>
								)}
							/>

							<p>
								<i>
									Note: <strong>continuous</strong> can be used for steel&iron factories, paper,
									glass, concrete production, CHP, biogas plants and Energy Producers;{' '}
									<strong>discontinuous</strong> for food and textile industry.
								</i>
							</p>

							<FormItem
								name="temperature"
								label="temperature"
								component={({input}) => (
									<input {...input} type="number" placeholder="300" step="5" min="20" max="700" />
								)}
								suffix="°C"
								{...leftFieldLayout}
							/>

							<FormItem
								name="power_available"
								label="thermal power"
								component={({input}) => (
									<input
										{...input}
										type="number"
										placeholder="300"
										step="10"
										min="20"
										max="20000"
									/>
								)}
								suffix="kW"
								{...leftFieldLayout}
							/>
						</div>
						<div
							className="column"
							style={{
								position: 'relative',
								display: 'flex',
								flexDirection: 'column',
								...(DEBUG && {
									backgroundColor: color('#3284cd')
										.alpha(0.2)
										.string(),
								}),
								//paddingLeft: spaceBetween,
							}}
						>
							<div
								style={{
									flex: 1,
									backgroundColor: color('grey')
										.alpha(0.1)
										.string(),
									borderRadius: 8,
									boxShadow: `0 0 20px rgba(0,0,0,0.2)`,
									padding: 10,
									margin: -10,
									marginBottom: 20,
								}}
							>
								<h6 className="title is-6">ADVANCED INPUT</h6>

								<FormItem
									name="thermic_energy_cost"
									label="thermal energy cost"
									component={({input}) => (
										<input
											{...input}
											type="number"
											placeholder="0.05"
											step="0.025"
											min="0.025"
											max="0.2"
										/>
									)}
									suffix="€/kWh"
									//error="This field is required"
									{...rightFieldLayout}
								/>

								<FormItem
									name="electric_energy_cost"
									label="electricity cost"
									component={({input}) => (
										<input
											{...input}
											type="number"
											placeholder="0.05"
											step="0.01"
											min="0.03"
											max="0.12"
										/>
									)}
									suffix="€/kWh"
									{...rightFieldLayout}
								/>
								<FormItem
									name="grant"
									label="grant"
									component={({input}) => (
										<Select {...input}>
											<option value={0}>0</option>
											<option value={0.1}>10</option>
											<option value={0.2}>20</option>
											<option value={0.3}>30</option>
											<option value={0.4}>40</option>
											<option value={0.5}>50</option>
										</Select>
									)}
									suffix="%"
									{...rightFieldLayout}
								/>
								<FormItem
									name="tee"
									label="incentive for saved TOE"
									component={({input}) => (
										<Select {...input}>
											<option value={0}>0</option>
											<option value={100}>100</option>
											<option value={200}>200</option>
											<option value={300}>300</option>
											<option value={400}>400</option>
										</Select>
									)}
									suffix="€/TOE"
									{...rightFieldLayout}
								/>
								<FormItem
									name="district_length"
									label="district heating length"
									component={({input}) => (
										<input
											{...input}
											type="number"
											placeholder="2500"
											step="200"
											min="500"
											max="8500"
										/>
									)}
									suffix="m"
									{...rightFieldLayout}
								/>
							</div>

							<button
								type="submit"
								className={`button is-primary ${loading && ' is-loading is-disabled'}`}
								css={buttonCss}
								style={{
									alignSelf: 'flex-start',
								}}
							>
								Calculate
							</button>
						</div>
						{/* <pre style={{fontSize: 8, lineHeight: '8px'}}>{JSON.stringify(values, null, 2)}</pre> */}
					</Layout>
				</form>
			)}
		/>
	)
}

export default InputForm
