/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import color from 'color'
import {compact as _compact} from 'lodash'

import config from '../../../config-loader'
const {DEBUG_TABLE = false} = config

const performerRenderer = (key: string, renderer: (v: string) => string = v => v) => (
	v: string,
	r: {__overallBestPerformances: string[]}
) => {
	const _v = renderer(v)
	if (r.__overallBestPerformances && r.__overallBestPerformances.includes(key)) {
		return (
			<span
				style={{
					fontWeight: 'bold',
					// color: '#6BCB6B',
					color: color('#6BCB6B')
						// .lighten(0.2)
						.toString(),
				}}
			>
				{_v}
			</span>
		)
		// } else if (r.__relativeBestPerformances && r.__relativeBestPerformances.includes(key)) {
		// 	return <span style={{fontWeight: 'bold', color: 'green'}}>{_v}</span>
	} else {
		return _v
	}
}

function sorterFactory(dataIndex: string) {
	switch (dataIndex) {
		case 'technology':
			return (a: any, b: any) => {
				const va = a[dataIndex]
				const vb = b[dataIndex]
				if (va > vb) return 1
				if (va < vb) return -1
				return 0
			}
		default:
			return (a: any, b: any) => {
				const va = parseFloat(a[dataIndex])
				const vb = parseFloat(b[dataIndex])
				if (va > vb || isNaN(vb)) return 1
				if (va < vb || isNaN(va)) return -1
				return 0
			}
	}
}

const columns = _compact([
	DEBUG_TABLE && {
		title: 'id',
		dataIndex: 'id',
	},
	{
		title: 'Technology',
		dataIndex: 'technology',
	},
	DEBUG_TABLE && {
		title: 'source_set',
		dataIndex: 'source_set',
	},
	{
		title: (
			<span>
				Power Installed
				<br />
				<small>[kW]</small>
			</span>
		),
		dataIndex: 'p_1',
		render: (v: string, c: any) =>
			c['p_2'] ? (
				<span
					style={{
						fontSize: '70%',
						lineHeight: 1.4,
						display: 'inline-block',
						marginTop: -9,
						position: 'relative',
						top: 5,
					}}
				>
					ORC: {c['p_1']}
					<br />
					DISTRICT: {c['p_2']}
				</span>
			) : (
				v
			),
	},
	{
		title: (
			<span>
				Power Recovered
				<br />
				<small>[kW]</small>
			</span>
		),
		dataIndex: 'p_actual',
		render: (v: string | null) => (v ? Math.round(parseFloat(v)) : '-'),
	},
	// DEBUG_TABLE &&
	{
		title: (
			<span>
				Mass Flow Rate
				<br />
				<small>[kg/s]</small>
			</span>
		),
		dataIndex: 'mass_flow_rate',
	},
	{
		title: (
			<span>
				Temp.
				<br />
				<small>[°C]</small>
			</span>
		),
		dataIndex: 'temperature',
	},
	{
		title: (
			<span>
				District Length
				<br />
				<small>[km]</small>
			</span>
		),
		dataIndex: 'length_district',
		render: (v: string | null) => (v ? parseFloat(v) / 1000 : '-'),
	},
	// {
	// 	title: (
	// 		<span>
	// 			Electric Energy Cost
	// 			<br />
	// 			<small>[€/kWh]</small>
	// 		</span>
	// 	),
	// 	dataIndex: 'c_el',
	// 	render: (v: string | null) => (v ? v : '-'),
	// },
	// {
	// 	title: (
	// 		<span>
	// 			Thermal Energy Cost
	// 			<br />
	// 			<small>[€/kWh]</small>
	// 		</span>
	// 	),
	// 	dataIndex: 'c_th',
	// 	render: (v: string | null) => (v ? v : '-'),
	// },
	// {
	// 	title: (
	// 		<span>
	// 			TEE
	// 			<br />
	// 			<small>[€/tep]</small>
	// 		</span>
	// 	),
	// 	dataIndex: 'tee',
	// },
	// {
	// 	title: (
	// 		<span>
	// 			Grant
	// 			<br />
	// 			<small>[%]</small>
	// 		</span>
	// 	),
	// 	dataIndex: 'grant',
	// 	render: (v: string | null) => (v ? parseFloat(v) * 100 : '-'),
	// },
	{
		title: (
			<span>
				Payback
				<br />
				<small>[years]</small>
			</span>
		),
		dataIndex: 'pb',
		render: performerRenderer('pb', v => parseFloat(v).toFixed(1)),
	},
	{
		title: 'DSCR',
		dataIndex: 'dscr',
		render: performerRenderer('dscr', v => parseFloat(v).toFixed(2)),
	},
	{
		title: (
			<span>
				IRR
				<br />
				<small>[%]</small>
			</span>
		),
		dataIndex: 'irr',
		render: performerRenderer('irr', v => (100 * parseFloat(v)).toFixed(1)),
	},
	{
		title: (
			<span>
				NPV
				<br />
				<small>[€]</small>
			</span>
		),
		dataIndex: 'npv',
		render: performerRenderer('npv', v => parseFloat(v).toFixed(0)),
	},
	{
		title: (
			<span>
				CO2
				<br />
				<small>[t/year]</small>
			</span>
		),
		dataIndex: 'co2',
		render: performerRenderer('co2', v => parseFloat(v).toFixed(0)),
	},
	{
		title: (
			<span>
				PES
				<br />
				<small>[TOE]</small>
			</span>
		),
		dataIndex: 'pes',
		render: performerRenderer('pes', v => parseFloat(v).toFixed(0)),
	},
	// {
	// 	title: 'p_2',
	// 	dataIndex: 'p_2',
	// },
	// {
	// 	title: 'p_actual_2',
	// 	dataIndex: 'p_actual_2',
	// },
	// {
	// 	title: 'Notes',
	// 	dataIndex: 'notes',
	// },
]).map(c => ({
	...c,
	sorter: sorterFactory(c.dataIndex),
}))

export default columns
