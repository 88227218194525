var _ = require('lodash');

var _require = require('./utils'),
    fixMax = _require.fixMax,
    calculatePossibleValues = _require.calculatePossibleValues;

var RESTRICTIONS = ['pb < 20', 'npv >= 0', 'irr >= 0.035', 'dscr > 1'];
var PARAMS = {
  INPUT: {
    electric_energy_cost: 'c_el',
    thermic_energy_cost: 'c_th',
    grant: 'grant',
    district_length: 'length_district',
    mass_flow_rate: 'mass_flow_rate',
    power_available: 'p_actual',
    tee: 'tee',
    temperature: 'temperature'
  }
};
var DB_COLUMNS = {
  INPUT: _.keys(PARAMS.INPUT).map(function (param) {
    return PARAMS.INPUT[param];
  })
};
var POWER = {
  he: fixMax({
    min: 20,
    max: 10000,
    step: 100
  }),
  hp: fixMax({
    min: 30,
    max: 1200,
    step: 30
  }),
  abs: fixMax({
    min: 100,
    max: 4000,
    step: 100
  }),
  district: fixMax({
    min: 100,
    max: 20000,
    step: 1000
  }),
  orc: fixMax({
    min: 100,
    max: 4000,
    step: 200
  })
};
var TEMPERATURE = {
  abs: fixMax({
    min: 200,
    max: 700,
    step: 100
  }),
  orc: fixMax({
    min: 200,
    max: 700,
    step: 100
  }),
  he: fixMax({
    min: 200,
    max: 700,
    step: 100
  }),
  district: fixMax({
    min: 200,
    max: 700,
    step: 100
  }),
  hp: fixMax({
    min: 20,
    max: 60,
    step: 5
  }),
  match: fixMax({
    min: 400,
    max: 700,
    step: 100
  })
};
var MAX_TEMPERATURE = {
  hp: 150
};
var GRANT = fixMax({
  min: 0,
  max: 0.5,
  step: 0.1
});
var TEE = fixMax({
  min: 100,
  max: 400,
  step: 100
});
var ELECTRICITY_COST = fixMax({
  min: 0.025,
  max: 0.2,
  step: 0.025
});
var THERMAL_ENERGY_COST = fixMax({
  min: 0.03,
  max: 0.12,
  step: 0.01
});
var DISTRICT_LENGTH = fixMax({
  min: 500,
  max: 8500,
  step: 2000
});
var TECHNOLOGIES = [{
  name: 'ORC',
  // remove if no electric_energy_cost
  acronym: 'orc',
  applicaple_on_discontinuous_profile: true,
  production_type: 'electric'
}, {
  name: 'Absorption Chiller',
  // remove if no thermic_energy_cost
  acronym: 'abs',
  applicaple_on_discontinuous_profile: true,
  production_type: 'thermic'
}, {
  name: 'Heat Exchanger',
  // remove if no thermic_energy_cost
  acronym: 'he',
  applicaple_on_discontinuous_profile: true,
  production_type: 'thermic'
}, {
  name: 'Heat Pump',
  // remove if no thermic_energy_cost
  acronym: 'hp',
  applicaple_on_discontinuous_profile: true,
  production_type: 'thermic'
}, {
  name: 'District Heating',
  // remove if no thermic_energy_cost, no district_length or emission_profile === 'discontinuous
  acronym: 'district',
  applicaple_on_discontinuous_profile: false,
  production_type: 'thermic'
}, {
  name: 'Match',
  // remove if (no thermic_energy_cost or no electric_energy_cost), no district_length or emission_profile === 'discontinuous
  acronym: 'match',
  applicaple_on_discontinuous_profile: false,
  production_type: 'mixed'
}];

var SOURCE_SETS = _.flatten(TECHNOLOGIES.map(function (tech) {
  return tech.applicaple_on_discontinuous_profile ? ['_all_day', '_friday'].map(function (suffix) {
    return tech.acronym + suffix;
  }) : [tech.acronym];
}));

var DEFAULTS = {
  grant: 0.2,
  tee: 100,
  thermic_energy_cost: 0.05,
  electric_energy_cost: 0.05
};
var OPTIMIZERS = {
  co2: function co2(a, b) {
    return a > b;
  },
  dscr: function dscr(a, b) {
    return a > b;
  },
  irr: function irr(a, b) {
    return a > b;
  },
  npv: function npv(a, b) {
    return a > b;
  },
  pb: function pb(a, b) {
    return a < b;
  },
  pes: function pes(a, b) {
    return a > b;
  }
};
var EmissionType = {
  FUMES: 'fumes',
  WATER: 'water'
};
var EmissionProfile = {
  CONTINUOUS: 'continuous',
  DISCONTINUOUS: 'discontinuous'
};
module.exports = {
  MAX_TEMPERATURE: MAX_TEMPERATURE,
  RESTRICTIONS: RESTRICTIONS,
  PARAMS: PARAMS,
  DB_COLUMNS: DB_COLUMNS,
  TECHNOLOGIES: TECHNOLOGIES,
  SOURCE_SETS: SOURCE_SETS,
  POWER: POWER,
  TEMPERATURE: TEMPERATURE,
  GRANT: GRANT,
  TEE: TEE,
  ELECTRICITY_COST: ELECTRICITY_COST,
  THERMAL_ENERGY_COST: THERMAL_ENERGY_COST,
  DISTRICT_LENGTH: DISTRICT_LENGTH,
  DEFAULTS: DEFAULTS,
  OPTIMIZERS: OPTIMIZERS,
  EmissionType: EmissionType,
  EmissionProfile: EmissionProfile
};