/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import color from 'color'

import {Layout} from './layout'

const DescriptionBox = () => {
	const _css = css`
		&,
		h1 {
			color: white;
		}
		& {
			background-color: #e74623;
			background-image: -webkit-linear-gradient(left, #e74623 0%, #ef7f1a 100%);
		}
		a {
			color: inherit;
			font-style: italic;
			font-weight: bold;
			&:hover {
				text-decoration: underline;
			}
		}
	`
	return (
		<Layout css={_css}>
			<h1 className="title">Decision Support System</h1>
			<div className="content">
				<p>
					The Decision Support System (DSS) is a tool which shows how waste heat could be recovered
					in industrial processes. The minimum data required for the simulation can be taken from
					the <a href="http://www.waste-heat.eu/waste-heat-potential">CE-HEAT cadastre</a> and more
					data can be further added by users to refine the results. The output is a pre-feasibility
					study useful also for policy makers to define which incentive schemes should be developed
					to make waste heat recovering an attractive investment.
				</p>

				<p>The considered technologies are:</p>
				<ul>
					<li>Organic Rankine Cycle (ORC) to produce electricity</li>
					<li>Absorption regeneration unit (ABS) for cooling</li>
					<li>Heat Exchanger (HE) for heating (ex. Pre-heating in a productive process)</li>
					<li>District Heating (District) for district heating (length of the grid is required)</li>
					<li>Heat Pump (HP) to rise the temperature of a source at a higher temperature</li>
					<li>
						Match solution (MATCH) with the installation of both an ORC and a District Heating
					</li>
				</ul>

				<p>
					The DSS provides two energy environmental parameters - avoided CO2 emissions (CO2) and
					Primary Energy Saving (PES) - and four financial parameters - Payback Period (PB),
					Internal Rate of Return (IRR), Net Present Value (NPV) and the Debt Service Coverage Ratio
					(DSCR).
				</p>
			</div>
		</Layout>
	)
}

export default DescriptionBox
