/**
 * Get number as input and return the number of decimals.
 * Credit to Mourner (taken from https://stackoverflow.com/a/27865285/1440302)
 */
function precision(a) {
  if (!isFinite(a)) return 0;
  var e = 1,
      p = 0;

  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p++;
  }

  return p;
}
/**
 * Sum with fixed precision.
 */


function sum(a, b) {
  var maxPrecision = Math.max(precision(a), precision(b));
  var multiplier = Math.pow(10, maxPrecision);
  return (a * multiplier + b * multiplier) / multiplier;
}
/**
 * Gets the highest value that is compatible with min and step, and which is lower than the provided maximum.
 * This should be the max value actually used in the calculations.
 */


function fixMax(_ref) {
  var min = _ref.min,
      max = _ref.max,
      step = _ref.step;
  var current = min;

  while (true) {
    if (sum(current, step) > max) return {
      min: min,
      max: current,
      step: step
    };
    current = sum(current, step);
  }
}

module.exports = {
  precision: precision,
  sum: sum,
  fixMax: fixMax
};