/** @jsx jsx */
import {jsx, css} from '@emotion/core'

import React from 'react'
import 'bulma'

import axios from 'axios'
import qs from 'querystring'
import {sortBy as _sortBy, partition as _partition} from 'lodash'

import {scroller} from 'react-scroll'

import {EmissionType, EmissionProfile, DEFAULTS} from '@ce-heat-dss/common/constants'

import Description from './components/description-box'
import InputForm from './components/input-form'

import Results from './components/results'

import config from './config-loader'
const {USE_DEV_DATA = false} = config

const outputKeys = ['pb', 'dscr', 'irr', 'npv', 'co2', 'pes']

function sortData(data) {
	const [bestPerformers, others] = _partition(data, item => item.__overallBestPerformances)
	return [
		...bestPerformers.sort((a, b) => {
			const ia = a.__overallBestPerformances.map(k => outputKeys.indexOf(k)).sort()[0]
			const ib = b.__overallBestPerformances.map(k => outputKeys.indexOf(k)).sort()[0]
			if (ia > ib) return 1
			if (ia < ib) return -1
			return 0
		}),
		..._sortBy(others, outputKeys),
	]
}

const initialInputValues = {
	// emission_type: EmissionType.FUMES,
	// emission_profile: EmissionProfile.CONTINUOUS,
	// temperature: 300,
	// power_available: 300,
	// electric_energy_cost: DEFAULTS.electric_energy_cost,
	// thermic_energy_cost: DEFAULTS.thermic_energy_cost,
	// tee: DEFAULTS.tee,
	// grant: DEFAULTS.grant,

	emission_type: EmissionType.WATER,
	emission_profile: EmissionProfile.CONTINUOUS,
	// temperature: 500, // very high temperature
	// power_available: 250,
	// district_length: 5000, // good for district

	// electric_energy_cost: 0.1,
	// thermic_energy_cost: 0.1,
	// grant: 0.2,
	// tee: 100,

	// temperature: 500,
	// power_available: 3050,
	// district_length: 2100,
	// electric_energy_cost: 0.1,
	// thermic_energy_cost: 0.1,
	// grant: 0.2,
	// tee: 100,

	...DEFAULTS,

	temperature: 500,
	power_available: 2000,
	district_length: 2500,
	// electric_energy_cost: 0.1,
	// thermic_energy_cost: 0.1,
	// grant: 0.2,
	// tee: 100,
}

function App() {
	const [{data, values, loading, error}, setState] = React.useReducer(
		(oldState, newState) => ({...oldState, ...newState}),
		{
			loading: false,
			data: USE_DEV_DATA ? sortData(require('./dev-data').default) : undefined,
			values: initialInputValues,
		}
	)
	return (
		<div>
			<InputForm
				initialValues={initialInputValues}
				loading={loading}
				onSubmit={values => {
					if (loading) return
					setState({loading: true})
					const url = `/query?${qs.stringify(values)}`
					axios
						.get(url)
						.then(({data}) => {
							const sorted = sortData(data)
							setState({values, data: sorted, loading: false})
						})
						.catch(err => {
							setState({error: `Unknown error`, loading: false})
						})
						.then(() => {
							scroller.scrollTo('results', {
								duration: 800,
								delay: 500,
								smooth: 'easeInOutQuart',
							})
						})
				}}
			/>
			{error ? (
				<div>{error}</div>
			) : (
				data && (
					<Results
						name="results"
						css={
							loading &&
							css`
								& {
									filter: blur(4px);
									pointer-events: none;
								}
							`
						}
						data={data}
						values={values}
					/>
				)
			)}
		</div>
	)
}

export default App
