import color from 'color'

// colorScale={['gold', 'orange', 'tomato']}
const colors = {
	// orc: '#f4511e',
	// district: '#fff59d',
	// hp: '#dce775',
	// he: '#8bc34a',
	// abs: '#00796b',
	// match: 'olive',
	orc: '#845EC2',
	district: '#D65DB1',
	hp: '#FF6F91',
	he: '#FF9671',
	abs: '#FFC75F',
	match: color('#F9F871')
		.mix(color('red'), 0.5)
		.darken(0.1)
		.string(),
}

function getMaxima(data, keys) {
	return keys.reduce((memo, key) => {
		memo[key] = Math.max(...data.map(d => d[key]))
		return memo
	}, {})
}

function getMinima(data, keys) {
	return keys.reduce((memo, key) => {
		memo[key] = Math.min(...data.map(d => d[key]))
		return memo
	}, {})
}

export {colors, getMaxima, getMinima}
