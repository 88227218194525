/** @jsx jsx */
import {jsx} from '@emotion/core'

export default ({values}) => (
	<div className="content">
		Comparison between viable solutions to recover a <strong>{values.emission_profile}</strong>{' '}
		waste heat from <strong>{values.emission_type}</strong> taking in to consideration the following
		economic parameters:
		<ul>
			<li>thermal energy cost: {values.thermic_energy_cost} €/kWh</li>
			<li>electricity cost: {values.electric_energy_cost} €/kWh</li>
			<li>grant: {values.grant * 100} %</li>
			<li>incentive for saved TOE (Tonne Oil Equivalent): {values.tee} €/TOE</li>
			<li>technical life time: 15 years</li>
			<li>oper and maint cost: 20 %</li>
			<li>interest rate (for NPV calculation) : 4 %</li>
			<li>NPV period: 30 years</li>
			<li>inflation rate: 1.2 %</li>
		</ul>
	</div>
)
