/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import React from 'react'

import color from 'color'

import RadarChart from './charts/Radar'
import ScatterChart from './charts/Scatter'
import Legend from './charts/legend'
// import {Table as BulmaTable, columns} from './table/bulma'
import {Table} from './table/antd'

import {Layout} from '../layout'
import ResultsIntro from './intro'

const DEBUG_BOXES = false
const debugStyles = DEBUG_BOXES && {
	backgroundColor: color('#3284cd')
		.alpha(0.2)
		.string(),
}

export default ({className, data, values, ...props}) => {
	const [highlighted, setHighlighted] = React.useState(null)

	return (
		<div style={{width: '100%'}} className={className} {...props}>
			<Layout>
				{values && <ResultsIntro values={values} />}
				<Table
					data={data}
					highlighted={highlighted}
					handleOver={setHighlighted}
					css={css(`
                  table {
                    width: 100%
                  }
                `)}
				/>
				{/* <br />
				<br />
				<BulmaTable data={data} highlighted={highlighted} handleOver={setHighlighted} /> */}
			</Layout>
			{data && data.length && (
				<div className="columns" style={{width: '100%'}}>
					<div
						className="column"
						style={{
							marginRight: 40,
							...debugStyles,
						}}
					>
						<RadarChart
							data={data}
							style={{width: '100%'}}
							highlighted={highlighted}
							handleOver={setHighlighted}
						/>
					</div>
					<div
						className="column"
						style={{
							...debugStyles,
						}}
					>
						<ScatterChart
							data={data}
							style={{width: '100%'}}
							highlighted={highlighted}
							handleOver={setHighlighted}
						/>
					</div>
				</div>
			)}
			<div style={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: -20}}>
				<Legend
					data={data}
					style={{maxWidth: 345}}
					highlighted={highlighted}
					handleOver={setHighlighted}
				/>
			</div>
		</div>
	)
}
