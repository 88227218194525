import React from 'react'
import {VictoryLegend} from 'victory'
import color from 'color'
import {colors} from './utils'
export default ({data, style, highlighted, handleOver}) => (
	<div style={style}>
		<VictoryLegend
			orientation="horizontal"
			gutter={25}
			symbolSpacer={10}
			width={345}
			height={27}
			style={{
				// border: {stroke: 'black'}
				data: {
					opacity: ({id}) => (!highlighted || id === highlighted.id ? 1 : 0.1),
				},
				labels: {
					opacity: ({id}) => (!highlighted || id === highlighted.id ? 1 : 0.1),
				},
			}}
			events={[
				{
					target: 'data',
					eventHandlers: {
						onMouseOver: (evt, target) => {
							handleOver && handleOver(target.datum)
						},
						onMouseOut: () => {
							handleOver && handleOver(null)
						},
					},
				},
			]}
			data={data.map(({technology, id}) => ({
				name: technology.toUpperCase(),
				id,
				symbol: {
					//type: 'minus',
					fill: color(colors[technology])
						.lighten(0.2)
						.string(),
					stroke: colors[technology],

					strokeWidth: 3,
					size: 4,
				},
			}))}
		/>
	</div>
)
