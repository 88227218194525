import React from 'react'
import {VictoryChart, VictoryGroup, VictoryPolarAxis, VictoryArea, VictoryTheme} from 'victory'

import {
	getMaxima,
	// getMinima,
	colors,
} from './utils'

const keys = ['pb', 'dscr', 'irr', 'npv', 'co2', 'pes']
const rotate = 30 % (360 / 6)

function processData(data) {
	const maxByGroup = getMaxima(data, keys)
	// const minByGroup = getMinima(data, keys)
	return data.map(({technology, id, ...datum}) => ({
		technology,
		id,
		values: keys.map(key => {
			if (key !== 'pb') {
				return datum[key] / maxByGroup[key]
			} else {
				// const ref = 20 - best
				// const ref = worst - best
				// const best = minByGroup[key]
				const worst = maxByGroup[key]
				const ref = Math.min(20, worst + 3)
				return (ref - datum[key]) / ref
			}
		}),
	}))
}

export default ({style = {}, data, highlighted}) => {
	const keys = ['pb', 'dscr', 'irr', 'npv', 'co2', 'pes']
	const processed = React.useMemo(() => processData(data), [data])
	return (
		<div style={style}>
			<VictoryChart
				polar
				width={500}
				height={300}
				startAngle={rotate}
				endAngle={360 + rotate}
				domain={{y: [0, 1]}}
				theme={VictoryTheme.material}
			>
				<VictoryGroup>
					{processed.map(({id, technology, values}) => {
						return (
							<VictoryArea
								key={technology}
								style={{
									data: {
										strokeWidth: 2,
										fillOpacity: 0.2,
										fill: colors[technology],
										opacity: !highlighted || id === highlighted.id ? 1 : 0.1,
									},
								}}
								data={values}
							/>
						)
					})}
				</VictoryGroup>
				{keys.map((k, i) => (
					<VictoryPolarAxis
						key={k}
						axisValue={i}
						dependentAxis
						label={k.toUpperCase()}
						labelPlacement="perpendicular"
						// labelPlacement="vertical"
						style={{
							// grid: {stroke: 'none'},
							grid: {stroke: 'grey', opacity: 0.1, strokeWidth: 0.5},
							axis: {stroke: 'none'},
							axisLabel: {
								padding: 30,
							},
							tickLabels: {
								fontSize: 10,
								// ...(i === 1 || {display: 'none'}),
							},
						}}
						tickValues={[0.33, 0.66, 1]}
						tickFormat={t => t * 100 + '%'}
					/>
				))}
				<VictoryPolarAxis
					tickFormat={() => ''}
					style={{
						// tickLabels: {display: 'none'},
						axis: {stroke: 'none'},
						grid: {stroke: 'grey', opacity: 0.5},
					}}
				/>
			</VictoryChart>
		</div>
	)
}
