/** @jsx jsx */
import {jsx, css} from '@emotion/core'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import './style.css'

function render(App: any) {
	ReactDOM.render(<App />, document.getElementById('root'))
}

render(App)

if (module.hot) {
	module.hot.accept('./App', () => {
		const NextApp = require('./App').default
		render(NextApp)
	})
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
