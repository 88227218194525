import React from 'react'
import {
	VictoryChart,
	VictoryScatter,
	VictoryTheme,
	VictoryAxis,
	VictoryBar,
	VictoryLabel,
} from 'victory'
import color from 'color'

import {colors, getMaxima} from './utils'

const keys = ['pb', 'co2']
const co2Gradient = [color('gold'), color('hsl(82, 178%, 70.9%)').darken(0.4)]
const pbGradient = [color('gold'), color('red')]
const pbLabels = ['quicksilver', 'medium PB', 'long PB']

const PBTick = ({index, style, x1, y1, ...props}) => {
	return (
		<text
			x={x1}
			y={y1 - 18}
			textAnchor="middle"
			alignmentBaseline="hanging"
			style={{
				fill: '#3c6794',
				fontStyle: 'italic',
				fontWeight: 'normal',
			}}
		>
			{String(pbLabels[index])}
		</text>
	)
}

const GradientDefs = () => (
	<defs>
		<marker
			id="pbArrow"
			markerWidth="10"
			markerHeight="10"
			refX="1"
			refY="1"
			orient="auto"
			markerUnits="strokeWidth"
		>
			<path d="M0,0 L0,2 L3,1 z" fill={pbGradient[1]} />
		</marker>
		<marker
			id="co2Arrow"
			markerWidth="10"
			markerHeight="10"
			refX="3"
			refY="1"
			orient="auto"
			markerUnits="strokeWidth"
		>
			<path d="M3,0 L3,2 L0,1 z" fill={co2Gradient[1]} />
		</marker>
		<linearGradient
			id="co2Gradient"
			gradientUnits="userSpaceOnUse"
			x1="0%"
			x2="0%"
			y1="27%"
			y2="80%"
		>
			<stop
				offset="0%"
				style={{
					stopColor: co2Gradient[1],
				}}
			/>
			<stop offset="100%" style={{stopColor: co2Gradient[0]}} />
		</linearGradient>
		<linearGradient
			id="pbGradient"
			gradientUnits="userSpaceOnUse"
			x1="0%"
			x2="100%"
			y1="0%"
			y2="0%"
		>
			<stop offset="20%" style={{stopColor: pbGradient[0]}} />
			<stop offset="100%" style={{stopColor: pbGradient[1]}} />
		</linearGradient>
	</defs>
)

function Chart({style = {}, data, highlighted, handleOver}) {
	const [processed, domain] = React.useMemo(() => {
		const processed = data.map(({pb, co2, technology, id}, i) => ({x: pb, y: co2, technology, id}))
		const maxima = getMaxima(data, keys)
		const domain = {
			x: [0, Math.max(maxima['pb'] * 1.2, 20)],
			y: [0, maxima['co2'] * 1.2],
		}
		return [processed, domain]
	}, [data])

	return (
		<div style={style}>
			<VictoryChart
				theme={VictoryTheme.material}
				width={500}
				height={300}
				// animate={{duration: 500}}
				domain={domain}
			>
				<GradientDefs />
				<VictoryAxis
					label="payback [year]"
					axisLabelComponent={<VictoryLabel dx={230} />}
					style={{
						axis: {
							markerEnd: 'url(#pbArrow)',
							stroke: 'url(#pbGradient)',
							strokeWidth: 5,
						},
						axisLabel: {textAnchor: 'end', fontSize: 10, padding: 4},
						tickLabels: {
							padding: 9,
						},
					}}
				/>
				<VictoryAxis
					dependentAxis
					label="avoided CO2 [ton]"
					axisLabelComponent={<VictoryLabel dx={60} />}
					style={{
						axis: {
							stroke: 'url(#co2Gradient)',
							markerStart: 'url(#co2Arrow)',
							strokeWidth: 5,
						},
						axisLabel: {textAnchor: 'start', fontSize: 10, padding: 6},
						tickLabels: {
							padding: 11,
						},
					}}
					tickFormat={v => String(v)}
				/>

				<VictoryAxis
					style={{
						grid: {display: 'none'},
						axis: {display: 'none'},
						tickLabels: {display: 'none'},
					}}
					tickValues={[2.5, 7.5, 15]}
					tickComponent={<PBTick />}
				/>
				<VictoryBar data={[{x: 5, y: domain.y[1]}]} style={{data: {width: 1.5, fill: '#3c6794'}}} />
				<VictoryBar
					data={[{x: 10, y: domain.y[1]}]}
					style={{data: {width: 1.5, fill: '#3c6794'}}}
				/>
				<VictoryScatter
					style={{
						data: {
							fill: ({technology}) => color(colors[technology]).lighten(0.2),
							stroke: ({technology}) => color(colors[technology]),
							opacity: ({id}) => (!highlighted || id === highlighted.id ? 1 : 0.1),
							strokeWidth: 2,
						},
					}}
					size={3}
					data={processed}
					events={[
						{
							target: 'data',
							eventHandlers: {
								onMouseOver: (evt, target) => {
									handleOver && handleOver(target.datum)
								},
								onMouseOut: () => {
									handleOver && handleOver(null)
								},
							},
						},
					]}
				/>
			</VictoryChart>
		</div>
	)
}

export default Chart
