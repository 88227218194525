import React from 'react'
import {Table as ATable} from 'antd'
import 'antd/lib/table/style'

import columns from './columns'

const Table = ({data, highlighted, handleOver, ...props}) => {
	return (
		<ATable
			rowKey="id"
			dataSource={data}
			columns={columns}
			pagination={false}
			{...props}
			onRow={(record, index) => {
				const props = {
					onMouseOver: () => handleOver && handleOver(record),
					onMouseOut: () => handleOver && handleOver(null),
				}
				if (highlighted && highlighted.id !== record.id) {
					props.style = {opacity: 0.1}
				}
				return props
			}}
		/>
	)
}

export {columns, Table}
